/* progressbar */
.unload-progress {
  position: relative;
}

.alerticon {
  position: absolute;
  left: 29px;
  top: 16px;
}

.progressbar {
  position: absolute;
  left: 2px;
  top: 41px;
  width: 57px;
  height: 6px;
  border: 1px solid;
}

.percentage {
  height: 100%;
  background-color: #ff0000;
}

.alert {
  margin: 0px;
  width: 50px;
  height: 50px;
  float: left;
}
.engine-on {
  background: url("./concrete/working_status.png") no-repeat scroll center
    center transparent;
}
.engine-off {
  background: url("./concrete/working_status.png") no-repeat scroll center
    center transparent;
}
.state0-yellow {
  background: url("./concrete/alert-icon.png") no-repeat scroll 0px 0px
    transparent;
}
.state1-yellow {
  background: url("./concrete/alert-icon.png") no-repeat scroll -50px 0px transparent;
}
.state2-yellow {
  background: url("./concrete/alert-icon.png") no-repeat scroll -100px 0px transparent;
}
.state3-yellow {
  background: url("./concrete/alert-icon.png") no-repeat scroll -150px 0px transparent;
}
.state4-yellow {
  background: url("./concrete/alert-icon.png") no-repeat scroll -200px 0px transparent;
}
.state5-yellow {
  background: url("./concrete/alert-icon.png") no-repeat scroll -250px 0px transparent;
}
.state6-yellow {
  background: url("./concrete/alert-icon.png") no-repeat scroll -300px 0px transparent;
}
.state7-yellow {
  background: url("./concrete/alert-icon.png") no-repeat scroll -350px 0px transparent;
}
.state7-red {
  background: url("~/app-v1-assets/concrete/alert-icon.png") no-repeat scroll -350px
    0px transparent;
}
.state8-yellow {
  background: url("./concrete/alert-icon.png") no-repeat scroll -400px 0px transparent;
}
.state9-yellow {
  background: url("./concrete/alert-icon.png") no-repeat scroll -450px 0px transparent;
}
.state10-yellow {
  background: url("./concrete/alert-icon.png") no-repeat scroll -500px 0px transparent;
}
.state0-red {
  background: url("./concrete/alert-icon.png") no-repeat scroll 0px -50px transparent;
}
.state1-red {
  background: url("./concrete/alert-icon.png") no-repeat scroll -50px -50px
    transparent;
}
.state2-red {
  background: url("./concrete/alert-icon.png") no-repeat scroll -100px -50px
    transparent;
}
.state3-red {
  background: url("./concrete/alert-icon.png") no-repeat scroll -150px -50px
    transparent;
}
.state4-red {
  background: url("./concrete/alert-icon.png") no-repeat scroll -200px -50px
    transparent;
}
.state5-red {
  background: url("./concrete/alert-icon.png") no-repeat scroll -250px -50px
    transparent;
}
.state6-red {
  background: url("./concrete/alert-icon.png") no-repeat scroll -300px -50px
    transparent;
}
.state7-red {
  background: url("./concrete/alert-icon.png") no-repeat scroll -350px -50px
    transparent;
}
.state8-red {
  background: url("./concrete/alert-icon.png") no-repeat scroll -400px -50px
    transparent;
}
.state9-red {
  background: url("./concrete/alert-icon.png") no-repeat scroll -450px -50px
    transparent;
}
.state10-red {
  background: url("./concrete/alert-icon.png") no-repeat scroll -500px -50px
    transparent;
}
.state0-green {
  background: url("./concrete/alert-icon.png") no-repeat scroll 0px -100px transparent;
}
.state1-green {
  background: url("./concrete/alert-icon.png") no-repeat scroll -50px -100px
    transparent;
}
.state2-green {
  background: url("./concrete/alert-icon.png") no-repeat scroll -100px -100px
    transparent;
}
.state3-green {
  background: url("./concrete/alert-icon.png") no-repeat scroll -150px -100px
    transparent;
}
.state4-green {
  background: url("./concrete/alert-icon.png") no-repeat scroll -200px -100px
    transparent;
}
.state5-green {
  background: url("./concrete/alert-icon.png") no-repeat scroll -250px -100px
    transparent;
}
.state6-green {
  background: url("./concrete/alert-icon.png") no-repeat scroll -300px -100px
    transparent;
}
.state7-green {
  background: url("./concrete/alert-icon.png") no-repeat scroll -350px -100px
    transparent;
}
.state8-green {
  background: url("./concrete/alert-icon.png") no-repeat scroll -400px -100px
    transparent;
}
.state9-green {
  background: url("./concrete/alert-icon.png") no-repeat scroll -450px -100px
    transparent;
}
.state10-green {
  background: url("./concrete/alert-icon.png") no-repeat scroll -500px -100px
    transparent;
}
.state0-white {
  background: url("./concrete/alert-icon.png") no-repeat scroll 0px -150px transparent;
}
.state1-white {
  background: url("./concrete/alert-icon.png") no-repeat scroll -50px -150px
    transparent;
}
.state2-white {
  background: url("./concrete/alert-icon.png") no-repeat scroll -100px -150px
    transparent;
}
.state3-white {
  background: url("./concrete/alert-icon.png") no-repeat scroll -150px -150px
    transparent;
}
.state4-white {
  background: url("./concrete/alert-icon.png") no-repeat scroll -200px -150px
    transparent;
}
.state5-white {
  background: url("./concrete/alert-icon.png") no-repeat scroll -250px -150px
    transparent;
}
.state6-white {
  background: url("./concrete/alert-icon.png") no-repeat scroll -300px -150px
    transparent;
}
.state7-white {
  background: url("./concrete/alert-icon.png") no-repeat scroll -350px -150px
    transparent;
}
.state8-white {
  background: url("./concrete/alert-icon.png") no-repeat scroll -400px -150px
    transparent;
}
.state9-white {
  background: url("./concrete/alert-icon.png") no-repeat scroll -450px -150px
    transparent;
}
.state10-white {
  background: url("./concrete/alert-icon.png") no-repeat scroll -500px -150px
    transparent;
}

.state8-drum_problem {
  background: url("./concrete/alert-icon.png") no-repeat scroll -543px -150px
    transparent;
}
