.clickRowStyl {
  background-color: #172b4d;
  color: #ffffff;
  font-size: '16px';
  font-family: 'Prompt, Medium' !important;
}

.custom-table-truckincorrectpoint-Yellow {
  background-color: #ffc031;
  color: #ffffff;
  font-size: '16px';
  font-family: 'Prompt, Medium' !important;
}

.custom-table-truckincorrectpoint-Orange {
  background-color: #ff7f00;
  color: #ffffff;
  font-size: '16px';
  font-family: 'Prompt, Medium' !important;
}

.custom-table-truckincorrectpoint-Red {
  background-color: #f53c56;
  color: #ffffff;
  font-size: '16px';
  font-family: 'Prompt, Medium' !important;
}

.custom-table-truckincorrectpoint-Default {
  background-color: #ffffff;
  color: #000000;
  font-size: '16px';
  font-family: 'Prompt, Medium' !important;
}

.marker-labels {
  margin-top: -3px;
  padding: 5px;
  position: absolute;
  visibility: visible;
  z-index: 1030;
  transform: translate(-35%, -70%);
}

.marker-labels .arrow {
  border-top-color: #ffffff;
  border-right-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0);
  border-left-color: rgba(0, 0, 0, 0);
  border-width: 5px 5px 0;
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-style: solid;
  height: 0;
  position: absolute;
  width: 0;
}

.marker-labels .arrow-point {
  border-radius: 50px;
  border: 1px solid #ffffff;
  bottom: -18.85px;
  background-color: #28bdaf;
  left: 50%;
  margin-left: -6px;
  height: 10px;
  position: absolute;
  width: 10px;
  z-index: 1;
}

.marker-labels .arrow-point-2 {
  border-radius: 50px;
  border: 2px solid #28bdaf;
  bottom: -22px;
  background-color: #ffffff;
  left: 50%;
  margin-left: -9px;
  height: 14px;
  position: absolute;
  width: 14px;
}

.marker-labels .arrow-point-3 {
  border-radius: 50px;
  border: 2px solid #28bdaf;
  bottom: -22px;
  background-color: #ffffff;
  left: 50%;
  margin-left: -9px;
  height: 16px;
  position: absolute;
  width: 16px;
}

.marker-labels .inner {
  background-color: #ffffff;
  border: 1px solid #ededed;
  border-radius: 32px;
  color: #515151;
  font-weight: 700;
  width: 100px;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
  font-size: 11px;
}

.marker-labels .site-icon-marker {
  width: 18px;
  height: 18px;
  padding-top: 5%;
  padding-left: 5%;
  position: absolute;
  bottom: 25%;
  left: 5%;
}

.marker-labels .site-icon-marker-truck-incorrect-point {
  width: 22px;
  height: 22px;
  padding-top: 3%;
  padding-left: 5%;
  position: absolute;
  bottom: 25%;
  left: 5%;
}
