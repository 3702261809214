body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  background: #ececec;
  border-color: #ececec;
  border: 0;
}
body {
  background: #ececec;
}

.scroll-style::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-top-right-radius: 70px;
  border-bottom-right-radius: 70px;
}
#components-table-demo-resizable-column .react-resizable {
  position: relative;
  background-clip: padding-box;
}

#components-table-demo-resizable-column .react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}
.scroll-style::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}
/* table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
} */
/* td {
  text-align: center;
} */
.tr__td__style {
  text-align: center;
  /* border: 1px solid black; */
}
.border__right__style {
  border-right: 0.1px solid #e9e9e9;
  padding: 10px;
  font-weight: lighter;
}
.border__right__bottom__style {
  border-right: 0.1px solid #e9e9e9;
  border-bottom: 0.1px solid #e9e9e9;
  padding: 10px;
  font-weight: lighter;
}
.border__right__td__style {
  border-right: 0.1px solid #cfcfcf;
  border-bottom: 0.1px solid #cfcfcf;
  padding: 15px;
}
.border__bottom__td__style {
  border-bottom: 0.1px solid #cfcfcf;
}
.scrolling__table {
  overflow-x: auto;
  overflow-y: auto;
  height: 500px;
  border-radius: 5px 0 0 0;
}
.scroll-style::-webkit-scrollbar-thumb {
  background-color: #535353;
  border: 1px solid #555555;
  border-radius: 20px;
}

.popup-menu-custom {
  max-height: 400px;
  overflow: auto;
}

.icon-custom-table-tracking {
  font-size: 20px !important;
}

.ant-table-tbody {
  border-bottom: 1px solid #e8e8e8 !important;
  transition: all 0.3s, border 0s !important;
  vertical-align: text-top !important;
}
@keyframes invalid {
  from {
    background-color: red;
  }
  to {
    background-color: inherit;
  }
}
.invalid { /* Safari 4+ */ /* Fx 5+ */ /* Opera 12+ */
  animation: invalid 1s infinite; /* IE 10+ */
}

/** custom antd **/
.ant-card {
  line-height: 1.3 !important;
}
.ant-card-meta-title {
  font-size: 13px !important;
}
.ant-card-body {
  padding: 12px 8px 10px 12px !important;
}
.ant-card-actions {
  line-height: 0 !important;
}
.ant-card-actions li {
  height: 0.3px !important;
}
.ant-card-actions li span {
  line-height: 0 !important;
  font-size: 13px !important;
}

.button-link {
  background: none !important;
  border: none;
  padding: 0 !important;

  /*optional*/
  font-family: arial, sans-serif; /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.antd-pro-notice-icon-list
  .antd-pro-notice-icon-item
  .antd-pro-notice-icon-iconElement {
  font-size: 22px;
}
.ant-list-item-meta {
  margin-top: 5px;
}

.antd-pro-notice-icon-list
  .antd-pro-notice-icon-item
  .antd-pro-notice-icon-avatar {
  margin: 4px 0px 5px 0px;
}

.antd-pro-notice-icon-list
  .antd-pro-notice-icon-item
  .antd-pro-notice-icon-title {
  font-size: 12px;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.antd-pro-notice-icon-datetime {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}
.ant-table-thead {
  font-size: 16px;
  font-weight: bold !important;
}

.ant-list-item {
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}
.font-size-vehicle-thread {
  font-size: 15px !important;
}
.antd-pro-notice-icon-list .antd-pro-notice-icon-item {
  padding: 0px 10px 0px 10px;
}

.ant-form-vertical .ant-form-item-label {
  line-height: 16px !important;
  padding: 1px 0px 0px 0px !important;
  margin: 0px 0px 2px 0px !important;
}

.highlight-row {
  background-color: rgba(179, 224, 255, 0.3);
}

.highlight-row-light {
  background-color: rgba(179, 224, 255, 0.6);
}

.bg-gradient {
  background: linear-gradient(269deg, #397aff, #6288ff, #b4cdff);
  background-size: 600% 600%;
  animation: RowBGGradient 10s ease infinite;
}
@keyframes RowBGGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 51%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.ant-transfer-list {
  margin-left: 13px !important;
  margin-right: 13px !important;
}

.menu-item-wrap {
  white-space: normal !important;
  line-height: 40px !important;
  height: 100% !important;
  overflow: visible !important;
  display: inline-block !important;
}

/* .modal-driver-card:hover {
      opacity: 0.2;
    } */

@media only screen and (max-width: 576px) {
  .common-drawer {
    width: 80%;
  }
}

.alert-alarm-table {
  margin: 0px 0px 10px 0px !important;
}

/* .alert-alarm-table div table thead{
      background-color: #dededebf !important;
    }
    
    .alert-alarm-table div table thead tr th{
      border-style: none !important;
    } */

.alert-alarm-table div table tbody tr td {
  padding: 1px 2px !important;
}

.tracking-table {
  margin: 0px 2px 5px 0px !important;
  width: auto;
}

.tracking-table div table thead tr th {
  font-size: 16px;
}

.tracking-table div table tbody tr td {
  font-size: 12px;
  padding: 1px 4px !important;
}
a.ant-calendar-time-picker-btn:after {
  content: 'select time';
  font-size: 16px;
}
a.ant-calendar-time-picker-btn {
  font-size: 0;
}
/* ตาราง tracking เฉพาะ */

.tracking-table-tracking {
  margin: 0px 2px 5px 0px !important;
  width: auto;
}
.web__content__header__style {
  /* display: inline; */
  margin-right: 15px;
  margin-bottom: 3px;
  font-size: 16px;
  cursor: pointer;
  color: gray;
}

.web__content__header__style:hover {
  /* display: inline; */
  margin-right: 15px;
  margin-bottom: 3px;
  font-size: 16px;
  cursor: pointer;
  color: red;
}

.web__content__subheader__style {
  /* display: inline; */
  margin-right: 15px;
  margin-bottom: 3px;
  font-size: 16px;
  cursor: pointer;
  color: black;
}
.web__content__subheader__style:hover {
  margin-right: 15px;
  margin-bottom: 3px;
  font-size: 16px;
  cursor: pointer;
  color: red;
}
.web__hv__content {
  margin-right: 15px;
  margin-bottom: 3px;
  font-size: 16px;
  cursor: pointer;
  color: gray;
}
.web__content__subheader__style:hover .web__hv__content {
  margin-right: 15px;
  margin-bottom: 3px;
  font-size: 16px;
  cursor: pointer;
  color: red;
}

.web__content_submenu_style {
  margin-right: 13px;
  font-size: 16px;
  color: #172d4d;
}

.web__content__submenu__style {
  margin-right: 13px;
  font-size: 16px;
  color: gray;
}

.web__content__menuitem__style {
  margin-right: 13px;
  font-size: 16px;
  color: black;
}

/* .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  color: black !important;
  cursor: context-menu !important;
} */
.web__content__card__style {
  width: 100%;
  height: 48px;
  background-color: #fafafa !important;
  padding: 0px 0px 0px 1px !important;
}
.fix__width_style {
  display: inline-block;
  width: 95%;
  white-space: nowrap;
  overflow: hidden !important;
  /* text-overflow: ellipsis; */
}

.tracking-table-tracking div table thead tr th {
  font-size: 16px;
  /* font-weight: bold; */
  text-align: center;
}
.semi-bold-16 {
  font-family: 'Prompt', sans-serif !important;
  font-size: 1rem;
  color: black;
  font-weight: 500;
}
.semi-bold-18-black {
  /* font-family: 'Prompt, sans-serif' !important; */
  font-size: 1.125rem;
  color: black;
}

.semi-bold-18-8898AA {
  font-family: 'Prompt', sans-serif !important;
  font-size: 1.125rem;
  color: #8898aa;
}
.semi-bold-white-18 {
  font-family: 'Prompt', sans-serif !important;
  font-size: 1rem;
  color: #ffffff;
}
.semi-bold-14 {
  font-family: 'Prompt', sans-serif !important;
  font-size: 0.875rem;
  color: #172b4d;
}
.semi-bold-18 {
  font-family: 'Prompt', sans-serif !important;
  font-size: 1.125rem;
  color: #172b4d;
}
.semi-bold-19 {
  font-family: 'Prompt', sans-serif !important;
  font-size: 1.188rem;
  color: #172b4d;
}
.semi-bold-20 {
  font-family: 'Prompt', sans-serif !important;
  font-size: 1.25rem;
  color: #172b4d;
}
.semi-bold-24-9CA7B2 {
  font-family: 'Prompt,Medium,' !important;
  font-size: 1.5rem;
  color: #9ca7b2;
}

.semi-bold-30-9EA7B1 {
  font-family: 'Prompt,SemiBold,' !important;
  font-size: 1.875rem;
  color: #9ea7b1;
}

.semi-bold-24 {
  font-family: 'Prompt', sans-serif !important;
  font-size: 1.5rem;
  color: #172b4d;
}

.my-location-pin-point {
  font-family: 'Prompt', sans-serif !important;
  font-size: 0.9rem;
}

.regular-bold-14 {
  font-size: 0.875rem;
}
.regular-bold-12 {
  font-size: 0.75rem;
}

th.column-money,
td.column-money {
  text-align: right !important;
  margin-right: 30px;
}
th.column-text-center,
td.column-text-center {
  text-align: center !important;
  text-align: -webkit-center !important;
}
.card__web__content {
  border-radius: 0.75rem !important;
  max-width: 100%;
  min-width: 100%;
  min-height: 5rem;
  max-height: 5rem;
  background-color: white;
  cursor: pointer;
  color: black;
  font-size: 1rem !important;
}
.card__web__content:hover {
  background-color: red;
  color: white;
}

/* .card__web__content:focus {
  background-color: red;
  color: white;
} */

.divider__web__content > .ant-divider {
  margin: 0 !important;
}

.text__card__web__content {
  padding: 1.125rem 0 0 0.313rem;
  width: 80%;
  font-weight: 600;
  color: #172b4d;
}

.text_one_line_card_web_content {
  padding: 1.125rem 0 0 0.313rem;
  width: 80%;
  font-weight: 600;
  color: #172b4d;
}
.res_text__card__web__content {
  padding: 7px 0 0 5px;
  /* text-align: center; */
  width: 80%;
  font-weight: 600;
  color: #172b4d;
}
.alert__alarm__text__card__web__content {
  padding: 0px 0 0 5px;
  width: 80%;
  font-weight: 600;
  color: #172b4d;
}

.alert__alarm__twoline__text__card__web__content {
  padding: 7px 0 0 5px;
  width: 80%;
  font-weight: 600;
  color: #172b4d;
}

.report__text__card__web__content {
  padding: 7px 0 0 5px;
  font-weight: 600;
  color: #172b4d;
}
.report2__text__card__web__content {
  padding: 18px 0 0 5px;
  font-weight: 600;
  color: #172b4d;
}

.popover__web__content {
  width: 220px;
}

.icon__web__content {
  font-size: large;
  padding-top: 17 !important;
  color: #172b4d;
}

.report__alern__alarm__text__card__web__content {
  padding: 7px 0 0 5px;
  font-weight: 600;
  color: #172b4d;
}
.report__alern__alarm__info__text__card__web__content {
  padding: 0px 0 0 5px;
  font-weight: 600;
  color: #172b4d;
}

.three__line__web__content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.report3__text__card__web__content {
  padding: 0 0 0 5px;
  font-weight: 600;
  color: #172b4d;
}
.report3__twoline__text__card__web__content {
  padding: 0 0 0 5px;
  font-weight: 600;
  color: #172b4d;
}
.driver_license_info_box {
  margin-top: 0px;
}
@media screen and (min-width: 2115px) {
  .res_text__card__web__content {
    padding: 18px 0 0 5px;
    /* text-align: center; */
    width: 80%;
    font-weight: 600;
    color: #172b4d;
  }
  .report__text__card__web__content {
    padding: 18px 0 0 5px;
    font-weight: 600;
    color: #172b4d;
  }
  .report__alern__alarm__text__card__web__content {
    padding: 18px 0 0 5px;
    font-weight: 600;
    color: #172b4d;
  }
  .report__alern__alarm__info__text__card__web__content {
    padding: 7px 0 0 5px;
    font-weight: 600;
    color: #172b4d;
  }
  .report2__text__card__web__content {
    padding: 18px 0 0 5px;
    font-weight: 600;
    color: #172b4d;
  }
  .alert__alarm__text__card__web__content {
    padding: 7px 0 0 5px;
    width: 80%;
    font-weight: 600;
    color: #172b4d;
  }
  .alert__alarm__twoline__text__card__web__content {
    padding: 18px 0 0 5px;
    width: 80%;
    font-weight: 600;
    color: #172b4d;
  }
  .report3__text__card__web__content {
    padding: 18px 0 0 5px;
    font-weight: 600;
    color: #172b4d;
  }
  .report3__twoline__text__card__web__content {
    padding: 18px 0 0 5px;
    font-weight: 600;
    color: #172b4d;
  }
}

@media screen and (min-width: 1919px) and (max-width: 2000px) {
  .report3__twoline__text__card__web__content {
    padding: 5px 0 0 5px;
    font-weight: 600;
    color: #172b4d;
  }
  .report__alern__alarm__text__card__web__content {
    padding: 18px 0 0 5px;
    font-weight: 600;
    color: #172b4d;
  }
  .report__alern__alarm__info__text__card__web__content {
    padding: 0px 0 0 5px;
    font-weight: 600;
    color: #172b4d;
  }
  .driver_license_info_box {
    /* margin-top: /0rem; */
    margin-top: 0rem;
  }
}

@media screen and (max-width: 1746px) {
  .text__card__web__content {
    padding: 7px 0 0 5px;
    /* text-align: center; */
    width: 80%;
    font-weight: 600;
    color: #172b4d;
  }
  .alert__alarm__text__card__web__content {
    padding: 0px 0 0 5px;
    width: 80%;
    font-weight: 600;
    color: #172b4d;
  }
  .alert__alarm__twoline__text__card__web__content {
    padding: 7px 0 0 5px;
    width: 80%;
    font-weight: 600;
    color: #172b4d;
  }
}

.main__text__card__web__content {
  padding: 1.125rem 0 0 0.313rem;
  /* text-align: center; */
  width: 80%;
  font-weight: 600;
}

.icon__card__web__content {
  color: red;
  padding-right: 5px;
  font-size: 1.2rem !important;
}
.color__text__card__web__content {
  color: #172b4d;
}
.card__web__content:hover .icon__card__web__content {
  color: white;
}
.card__web__content:hover .color__text__card__web__content {
  color: white;
}
.card__web__content:hover .text__card__web__content {
  color: white;
}
.card__web__content:hover .res_text__card__web__content {
  color: white;
}
.card__web__content:hover .text_one_line_card_web_content {
  color: white;
}
.card__web__content:hover .report2__text__card__web__content {
  color: white;
}
.card__web__content:hover .report__text__card__web__content {
  color: white;
}
.card__web__content:hover .icon__web__content {
  color: white;
}

.card__web__content:hover .report3__twoline__text__card__web__content {
  color: white;
}
.card__web__content:hover .report3__text__card__web__content {
  color: white;
}
.card__web__content:hover .report__alern__alarm__text__card__web__content {
  color: white;
}
.card__web__content:hover .alert__alarm__twoline__text__card__web__content {
  color: white;
}
.card__web__content:hover .alert__alarm__text__card__web__content {
  color: white;
}
.card__web__content:hover
  .report__alern__alarm__info__text__card__web__content {
  color: white;
}

.left__button__web__content {
  padding: 0 20px 5px 0;
}

.divider__web__content > .ant-divider-horizontal {
  border-top: 2px solid #d3d3d3;
}

.divider_web_content > .ant-divider-horizontal {
  margin: 10px;
}

.tracking-table-tracking div table tbody tr td {
  font-size: 14px;
  padding: 10px !important;
}

.tracking-table-tracking.dense div table tbody tr td {
  padding: 0px 8px !important;
}

.current-location-tracking-style {
  font-size: 14px;
  margin-right: 4px;
  padding: 0px 2px 1px 2px;
  line-height: 14px;
  width: auto;
}

.tracking-detail-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  font-size: 12px;
}

.tracking-detail-table > tbody > tr > th {
  width: 135px;
}

.tracking-detail-table {
  border-left: solid #40a9ff 0.5px;
}

.tracking-detail-table-drawer {
  width: 100%;
  border-collapse: separate;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  border-spacing: 0px 5px;
  /* background-color: #0077ea; */
  /* border-left: solid #40a9ff 2px; */
}

.tracking-detail-table-drawer > tbody > tr > th {
  word-wrap: break-word;
  width: 145px;
}
.line-verticle {
  border-right: '';
  margin-right: 0%;
}

@media (min-width: 1200px) {
  .line-verticle {
    border-right: solid #efefef 2px;
    margin-right: 1%;
    height: 200px;
  }
  .tracking-detail-table-drawer {
    width: 100%;
    border-collapse: separate;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    border-spacing: 0px 20px;
    /* border-left: solid #40a9ff 2px; */
    /* background-color: #6288ff; */
  }
  .button-drawer-border-spacing {
    /* margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 50%); */
  }
}

.button-drawer-border-spacing {
  /* margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%); */
}

.button-drawer-popup {
  color: #0077ea !important;
  border-color: #0077ea !important;
}
.button-drawer-popup:hover {
  color: white !important;
  background-color: #0077ea !important;
}

/* .tracking-detail-drawer {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    font-size: 14;
    font-weight: bold;
  }
  .tracking-detail-drawer > label {
    word-wrap: break-word;
    width: 135px;
    margin-top: 10%;
  } */

.edge-btn-group button {
  line-height: 1.499;

  font-weight: 400;
  text-align: center;
  touch-action: manipulation;

  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;

  font-size: 14px;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: #d9d9d9;

  padding: 8px 15px; /* Some padding */
  cursor: pointer; /* Pointer/hand icon */
  font-weight: 400;
  text-align: center;
  display: block; /* Make the buttons appear below each other */
}

.edge-btn-group button:not(:last-child) {
  /* Prevent double borders */
  /* border-bottom: 0; */
}

/* .edge-btn-group button:hover {
      background: #1890ff;
      color: #fff;
    } */

/* Add a background color on hover */
.edge-btn-group button:hover,
.edge-btn-group button:focus {
  color: #40a9ff;
  background-color: #fff;
  border-color: #40a9ff;
}

.edge-element {
  /* border-radius: 0 !important; */
}
.button-search-smart-dispatching {
  /* width: 41vh !important;
  height: 48px !important; */
  margin: 0px 10px 0px 0px !important;
}

.button-search-smart-dispatching .ant-input-lg {
  border-radius: 10px !important;
  padding: 16px;
}
.edge-element-tracking {
  width: 100px !important;
}

@media only screen and (max-width: 1290px) {
  .edge-element-tracking {
    width: 86.9479px !important;
  }
}

.table-sign-4 {
  width: 30px;
  height: 30px;
}

.content-bold {
  font-weight: 700;
  font-size: 14px;
  text-decoration: underline;
}

.circleBase {
  border-radius: 50%;
}

.yellow-dot-marker {
  width: 6px;
  height: 6px;
  background: #ffd619;
  border: 0.2px solid black;
}
.red-dot-marker {
  width: 6px;
  height: 6px;
  background: #c91c1c;
  border: 0.2px solid black;
}
.type3 {
  width: 500px;
  height: 500px;
  background: aqua;
  border: 30px solid blue;
}

.tool-btn-active {
  color: #40a9ff !important;
  background-color: #fff !important;
  border-color: #40a9ff !important;
}

.infinite-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 24px;
}

.overlay {
  display: block;
  top: 0px;
  left: 0px;
  opacity: 0.2;
}

.dashcamera-cover-icon {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: grey;
}

.dashcamera-card {
  position: relative;
  text-align: center;
}

.overlay:hover {
  opacity: 1;
}

.dashcamera-card:hover .overlay {
  opacity: 1;
}

/* ///////////////////////////// test for replay ///////////////////////////////// */
.replay-info-box {
  padding: 15px 5px 5px 20px;
}
.replay-monitor {
  position: absolute;
  top: 5px;
  left: 5px;
}
.replay-speed-gauge {
  margin-left: 230px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.monitor {
  /* width: 278px;
      height: 220px; */
  overflow: none;
  border: solid 1em rgb(247, 247, 247);
  border-radius: 0.2em;
}
.monitor::-webkit-scrollbar {
  width: 15px;
}

/* .stand:before {
      content: "";
      display: block;
      position: relative;
      background: rgb(214, 214, 214);
      width: 110px;
      height: 50px;
      top: 270px;
      left: 90px;
    } */
/* .stand:after {
      content: "";
      display: block;
      position: relative;
      background: #333;
      border-top-left-radius: 0.5em;
      border-top-right-radius: 0.5em;
      width: 300px;
      height: 15px;
      top: 50px;
      left: 110px;
    } */

.gmnoprint a,
.gmnoprint span {
  display: none;
}
.gmnoprint div {
  background: none !important;
}
.helper {
  --reactour-accent: #5cb7b7;
  line-height: 1.3;
  color: #2d2323;
}
.replay-info-box .ant-statistic-content-value-decimal {
  font-size: 14px !important;
}
.custom-tooltip {
  line-height: 0.6;
  /* background-color: white; */
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.custom-tooltip-speed-overlimit {
  line-height: 0.6;
  /* background-color: white; */
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  padding: 15px 15px 0 15px;
}

.custom-tooltip-body {
  font-size: 13px;
  margin: 5px 5px 5px 5px;
}

.font_bg_big {
  font-family: 'Proxima Light', sans-serif;
  font-size: 600%;
  color: rgba(100, 100, 100, 0.3);
  overflow: hidden;
  padding: 0;
  margin: 0;
  bottom: -40px;
  right: 0;
  position: absolute;
}

.policy_form_delete_button {
  z-index: 20;
  overflow: hidden;
  padding: 0;
  margin: 0;
  top: 10;
  right: 0;
  position: absolute;
}

.booking-steps-content {
  padding: 0px 0px 0px 15px;
}

.form-divider {
  padding: 10px 0 !important;
  margin: 3px 0 !important;
}

.form-divider::before,
.form-divider::after {
  border-top: 1.4px solid #bdbdbd !important;
}

.form-divider-text {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600 !important;
  font-size: 17px;
  line-height: 1.4;
}

.vis-custom-time {
  background-color: red !important;
}

.vis-item.vis-background {
  background-color: rgba(7, 138, 245, 0.4) !important;
}

.safety-badge-text .ant-badge-status-text {
  font-size: 18px !important;
}

.safety-badge-text .ant-badge-status-dot {
  width: 9px;
  height: 10px;
}

.safety-checkbox-text {
  font-size: 11px !important;
}

.edge-btn-group .disabled-button button {
  background-color: #f1eded !important;
  cursor: not-allowed;
  opacity: 0.9;
}

.dashboard-concrete-step1 {
  margin-left: -1%;
}
.dashboard-concrete-step2 {
  margin-left: 11%;
}
.dashboard-concrete-step3 {
  margin-left: 8%;
}
.dashboard-concrete-step4 {
  margin-left: 6%;
}
.dashboard-concrete-step5 {
  margin-left: 10%;
}
.dashboard-concrete-step6 {
  margin-left: 12%;
}

.dashboard-concrete-step1-en {
  margin-left: -3%;
}
.dashboard-concrete-step2-en {
  margin-left: 3%;
}
.dashboard-concrete-step3-en {
  margin-left: 4%;
}
.dashboard-concrete-step4-en {
  margin-left: 10%;
}
.dashboard-concrete-step5-en {
  margin-left: 13%;
}
.dashboard-concrete-step6-en {
  margin-left: 12%;
}

.ant-table-header-column {
  width: 100%;
}

.blink {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.report-sorting-column-table {
  margin: 0px 2px 5px 0px !important;
  width: auto;
}

.report-sorting-column-table div table thead tr th {
  font-size: 9px;
}

.report-sorting-column-table div .ant-table-placeholder {
  display: none !important;
}

.compact-page-header {
  padding: 1px 12px !important;
}

.compact-page-header > .ant-page-header-footer {
  margin-top: 0px !important;
}

.compact-page-header
  > .ant-page-header-heading
  > .ant-page-header-heading-title {
  font-size: 14px !important;
  font-weight: 500 !important;
  padding-left: 7px;
}

.compact-page-header-new {
  padding: 1px 12px !important;
}

.compact-page-header-new > .ant-page-header-footer {
  margin-top: 0px !important;
}

.compact-page-header-new
  > .ant-page-header-heading
  > .ant-page-header-heading-title {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  color: #172b4d !important;
  padding-left: 7px;
}

.card-style-concrete-dashboard {
  width: 100%;
  min-width: 233px;
  border-radius: 10px !important;
  background-color: white !important;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
}

.content-card-scroll {
  overflow: auto;
  margin-right: 0 !important;
  margin-bottom: 60px !important;
  padding: 5px !important;
  padding-left: 15px !important;
  height: 330px !important;
}

.button-style-dashboard-concrete {
  width: 140px !important;
  height: 40px !important;
  background-color: #ff455f !important;
  color: white !important;
  border-radius: 10px !important;
  box-shadow: 0px 3px 11px #00000029 !important;
  margin-right: 10px;
  /* font-size: 0.8vw !important; */
}

.style-separate-detail-concrete-dashboard .ant-card-body {
  padding: 0 !important;
}

.drawer-style-concrete-dashboard .ant-drawer-content {
  background: #fafafa;
}

.drawer-style-concrete-dashboard .ant-drawer-content-wrapper {
  height: auto !important;
}

.highlight-row-dark {
  background-color: #ffeae6;
}

.ant-table-small > .ant-table-content .ant-table-header {
  /* background-color: #ff8f8f !important; */
  background-color: #ff455f !important;
}
.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0px !important;
}
.ant-table-thead {
  /* vertical-align: top !important; */
  /* background-color: #ff8f8f !important; */
  background-color: #ff455f !important;
}

.confirm-concrete-job-table {
  /* margin: 15px 2px 5px 0px !important; */
}

.confirm-concrete-job-table div table thead tr th {
  /* font-size: 0.9rem; */
  /* line-height: 1 !important; */
  /* color: white; */
}

.confirm-concrete-job-table div table tbody tr {
  /* line-height: 1 !important;
  background-color: white; */
}

.table-popup-detail-concrete-delivery {
  margin: 15px 2px 10px 0px !important;
}

.table-popup-detail-concrete-delivery div table thead tr th {
  font-size: 0.9rem;
  /* line-height: 1 !important; */
  color: white;
}

.tracking-table-tracking .ant-table-column-sorters {
  margin-right: auto !important;
  margin-left: auto !important;
}

.table-popup-detail-concrete-delivery div table tbody tr {
  line-height: 1 !important;
  background-color: white;
}

.select-dashboard-concrete > .ant-select-selection--multiple {
  min-height: 40px !important;
}

.select-dashboard-concrete .ant-select-selection__rendered {
  /* margin-top: 5px !important;
  margin-bottom: 5px !important; */
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.confirm-concrete-job-table div table tbody tr td {
  /* font-size: 0.9rem;
  padding-top: 15px !important;
  color: #172b4d !important; */
}

.color-filter-dashboard-concrete {
  color: '#172b4d';
}

.isupply-dashboard-table {
  margin: 0px 2px 5px 0px !important;
  width: auto;
}

.isupply-dashboard-table div table thead tr th {
  font-size: 12px;
  line-height: 1 !important;
}

.isupply-dashboard-table div table tbody tr {
  line-height: 1 !important;
}

.isupply-dashboard-table div table tbody tr td {
  font-size: 12px;
  padding: 1px 4px !important;
}

.compact-steps > div > div > .ant-steps-item-content {
  margin-top: 1px !important;
}

.to-go-row {
  background-color: #d2bc69;
}

.tooltip-marker {
  position: relative;
  display: inline-block;

  align-items: center;
  z-index: -2;
  display: inline-flex;
  transform: translate(-50%, -100%);
}

.tooltip-marker .tooltiptext-marker {
  visibility: hidden;
  width: 120px;
  background-color: rgb(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 155%;
  margin-left: -50px;
}

.tooltip-marker .tooltiptext-marker::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip-marker:hover .tooltiptext-marker {
  visibility: visible;
  transition-delay: 1s;
}

.tooltip-marker:active .tooltiptext-marker {
  visibility: visible;
}

.hide-col {
  display: none;
}

.wrap-marker {
  position: absolute;
  display: table;
  bottom: 25px;
  left: 17px;
  width: 100%;
}

.left-marker {
  width: 10px;
  /* background-color: grey; */
  display: table-cell;
}
.center-marker {
  padding: 0px 5px 0px 12px;
  /* min-width: 30px; */
  display: table-cell;
  /* background-color: yellow; */
  vertical-align: middle;
}
.right-marker {
  padding: 0px 3px 0px 3px;
  width: auto;
  display: table-cell;
  /* background-color: red; */
  vertical-align: middle;
}

.tracking-ticket-info {
  position: absolute;
  bottom: 0;
  right: 0;
}

.ldroute_placeholder .ldroute_info {
  padding: 10px 10px 30px 10px !important;
}
span.ldroute_menu:nth-child(1) {
  display: none;
}
span.ldroute_menu:nth-child(4) {
  display: none;
}

.tooltip-marker-alwayshow .tooltiptext-marker-alwayshow {
  visibility: hidden;
  width: 120px;
  background-color: rgb(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 155%;
  margin-left: -48px;
  visibility: visible;
}

.tooltip-marker-alwayshow .tooltiptext-marker-alwayshow::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
  visibility: visible;
}
.bubble__outside {
  height: auto;
  width: 10%;
  min-width: 120px;
  background: ivory;
  display: block;
  margin: 0 auto;
  border-radius: 5px;
  /* margin-top: 50px; */
  padding-top: 10px;
  font-size: 14px;
  text-align: center;
  padding: 10px 10px 10px 10px;
  background-color: #051734;
  color: white;
}

.bubble__location {
  height: auto;
  min-height: 28px;
  width: 10%;
  min-width: 240px;
  background: ivory;
  display: block;
  margin: 0 auto;
  border-radius: 5px;
  /* margin-top: 50px; */
  padding-top: 10px;
  font-size: 14px;
  text-align: center;
  padding: 10px 10px 20px 10px;
  background-color: #051734;
  color: white;
}

.bubble {
  height: auto;
  width: 10%;
  min-width: 240px;
  background: ivory;
  display: block;
  margin: 0 auto;
  border-radius: 5px;
  /* margin-top: 50px; */
  padding-top: 10px;
  font-size: 14px;
  text-align: center;
  padding: 10px 10px 20px 10px;
  background-color: #051734;
  color: white;
}

.bubble__replay {
  height: auto;
  width: 10%;
  min-width: 240px;
  background: ivory;
  display: block;
  margin: 0 auto;
  border-radius: 5px;
  /* margin-top: 50px; */
  padding-top: 10px;
  font-size: 14px;
  text-align: center;
  padding: 10px 10px 20px 10px;
  background-color: #051734;
  color: white;
}

.pointer {
  height: 10px;
  width: 12px;
  background: ivory;
  margin: 0 auto;
  transform: rotate(45deg);
  border-radius: 0 0 -4px 0;
  margin-top: -6px;
  position: relative;
  /* left: calc(0.5vw - 96px); */
  background-color: #051734;
}

.pointer__replay {
  height: 10px;
  width: 12px;
  background: ivory;
  margin: 0 auto;
  transform: rotate(45deg);
  border-radius: 0 0 -4px 0;
  margin-top: 13px;
  position: relative;
  /* left: calc(0.5vw - 96px); */
  background-color: #051734;
}

.icon-marker {
  /* position: relative;
      align-items: center; */
  z-index: -2;

  /* display: inline-flex;
      transform: translate(-50%, -100%); */
}

th.column-text-center-kpi-driver,
td.column-text-center-kpi-driver {
  text-align: left !important;
  /* background-color: #e1e5ee; */
}

.popup-master-data-tracking {
  position: absolute;
  margin: 0.25rem 0.938rem 0rem 0rem;
  right: 3.438rem;
  top: 2.188rem;
  z-index: 12;
  width: 26.875rem;
  height: 2.688rem;
  background-color: rgb(255, 255, 255);
  border-radius: 0.188rem;
  border: 0.063rem solid #d9d9d9;
}
@media only screen and (max-width: 1746px) {
  .popup-master-data-tracking {
    position: absolute;
    margin: 0.25rem 0.938rem 0rem 0rem;
    right: 0.438rem;
    top: 2.188rem;
    z-index: 12;
    width: 26.875rem;
    height: 2.688rem;
    background-color: rgb(255, 255, 255, 0.9);
    border-radius: 0.188rem;
    border: 0.063rem solid #d9d9d9;
  }
}
@media only screen and (max-width: 1537px) {
  .popup-master-data-tracking {
    position: absolute;
    margin: 0.25rem 0.938rem 0rem 0rem;
    right: -2.562rem;
    top: 2.188rem;
    z-index: 12;
    width: 26.875rem;
    height: 2.688rem;
    background-color: rgb(255, 255, 255, 0.9);
    border-radius: 0.188rem;
    border: 0.063rem solid #d9d9d9;
  }
}

.sticky-button {
  margin-right: 10px;
  position: fixed;
  top: 95%;
  right: 0;
  transform: translateY(-50%);
  z-index: 100;
}

.sticky-button a {
  border-radius: 35px;
  display: block;
  text-align: center;
  padding: 9px 15px;
  /* transition: all 0.3s ease; */
  color: white;
  font-size: 20px;
  border: 1px solid #d9d9d9;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.sticky-button a:hover {
  /* background-color: #000; */
  opacity: 0.8;
}

.sticky-a {
  background: #fbfbfb;
  color: rgb(236, 216, 33);
}

.antd-pro-notice-icon-list
  .antd-pro-notice-icon-item
  .antd-pro-notice-icon-title {
  padding: 8px 0px 0px 0px !important;
}

.row-multi-drop-continues > td {
  border-top: none !important;
  border-bottom: none !important;
}

.row-multi-drop-head > td {
  border-top: 1px solid #e8e8e8;

  border-bottom: none !important;
}

/* new custom UI theme */
.ant-layout-sider {
  background: gray;
}

ul .ant-menu-light,
.ant-menu-light .ant-menu-sub {
  color: black !important;
  background: #ffffff !important;
}

/* .ant-menu-item:hover {
    color: #ff0b0b !important;
  } */

/* .ant-side-menu .ant-menu-submenu > div > span > i {
    color: #ff0b0b;
    font-size: '16px';
  }
  
  .ant-side-menu .ant-menu-submenu-active > div > span > i {
    color: #ffffff !important;
  }
  
  .ant-side-menu .ant-menu-submenu-active > * {
    color: #ffffff !important;
    background: #ff0b0b !important;
  } */
.ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
  font-family: Prompt, sans-serif;
}

.text-header-side-bar {
  font-size: 24px !important;
  color: red;
  font-weight: bold !important;
}
.ant-drawer-header {
  position: relative;
  padding: 20px 22px !important;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: 0px solid #e8e8e8 !important;
  border-radius: 0px 0px 0 0 !important;
}
.ant-layout-header {
  padding: 0px !important;
}

.ant-tabs-nav-scroll {
  display: flex;
  justify-content: space-around;
}

.tabs-right
  > .ant-tabs-bar
  > .ant-tabs-nav-container
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-scroll {
  display: block;
  justify-content: space-around;
}

/* .ant-menu-item a {
  font-size: 14 !important;
  font-weight: bold;
} */
.ant-menu-inline .ant-menu-item {
  font-size: 14 !important;
  font-weight: bold;
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  overflow: hidden;
  font-size: 16px !important;
  line-height: 40px;
  text-overflow: ellipsis;
  font-weight: bold;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  color: rgba(0, 0, 0, 0.65) !important;
}

.ant-menu-item-selected > a,
.ant-menu-item-selected > a:hover {
  color: rgba(0, 0, 0, 0.65) !important;
}

.ant-drawer-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgb(0 0 0 / 0%) !important;
}

.ant-layout {
  flex: auto;
  background: #b9b9b924;
  /* font-family: Prompt, sans-serif; */
}

.ant-table {
  font-size: 15px !important;
}

/* .ant-radio-group {
    width: 100% !important;
  } */
.radio-group-button-tracking-style {
  width: 100% !important;
}

/* .ant-radio-button-wrapper {
    width: 100% !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    text-align: center !important;
    outline: none !important;
  } */

.radio-button-tracking-style {
  width: 100% !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0 !important;
  text-align: center !important;
  outline: none !important;
}

.ant-radio-button-wrapper:focus {
  outline: none !important;
  border: none;
}
body {
  font-family: Prompt, sans-serif !important;
  /* font-weight: bold !important; */
}

.button-add-chauffeur {
  border-color: #ed5565 !important;
  border-radius: 30px !important;
  border-width: 2px !important;
  color: #ed5565 !important;
}

.button-add-chauffeur:hover {
  border-color: #ed5565 !important;
  border-radius: 30px !important;
  border-width: 2px !important;
  color: white !important;
  background-color: #ed5565 !important;
}

li.ant-menu-item:hover {
  background-color: #ff000070 !important;
}
.ant-menu-item > a:hover {
  color: rgb(50 49 49 / 75%) !important;
}
.hover-red:active {
  color: red;
}
.hover-red:hover {
  color: red;
}
.hober-red {
  background-color: white;
  color: black;
}
.common-text-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* table.components-table-demo-nested
  > tr.ant-table-row.ant-table-row-level-0:hover {
  cursor: pointer;
} */
.ant-table-row {
  font-size: 14px !important;
}
.test-cursor:hover {
  cursor: pointer;
}

.ant-table-thead
  > tr
  > th
  .ant-table-column-sorter
  .ant-table-column-sorter-inner {
  color: black !important;
}

.ant-badge.ant-badge-not-a-wrapper.ant-badge-status.replay-badge
  > .ant-badge-status-dot {
  width: 10px !important;
  height: 10px !important;
}

.ant-badge.ant-badge-not-a-wrapper.ant-badge-status.replay-badge
  > .ant-badge-status-text {
  font-size: 11px !important;
}

.ldmap_placeholder {
  border-radius: 0px;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 100px;
  min-height: 100;
  background: #eee;
  font: '12px/ 1.2 Prompt,Medium';
  overflow: hidden;
  z-index: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.custom-table-truckincorrectpoint-Orange {
  background-color: '#FF7F00';
  color: '#ffffff';
  font-size: '14px';
  font-family: 'Prompt, Medium' !important;
}

tr.ant-table-row.table-cement.ant-table-row-level-0 {
  background-color: #e6e6fa;
}

.header__text {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: 'black';
}

.red-dot {
  height: 0.75vw;
  width: 0.75vw;
  background-color: #f53c56;
  border-radius: 50%;
  display: inline-block;
}

.green-dot {
  height: 0.75vw;
  width: 0.75vw;
  background-color: #2dce98;
  border-radius: 50%;
  display: inline-block;
}

.gray-dot {
  height: 0.75vw;
  width: 0.75vw;
  background-color: gray;
  border-radius: 50%;
  display: inline-block;
}

.configDivider .ant-divider-horizontal {
  margin: 12px 0 12px 0;
}

/* md */
@media (min-width: 768px) {
  .tracking-box-dirver {
    text-align: center;
  }

  .tracking-box-swipe {
    text-align: center;
  }
}
/* lg */
@media (min-width: 992px) {
  .tracking-box-dirver {
    text-align: left;
    margin-top: 0.2rem;
    padding-left: 2rem !important;
  }

  .tracking-box-swipe {
    text-align: left;
    margin-top: 1rem;
    padding-left: 2rem !important;
  }
}

.select-style-route-type .ant-select-selection {
  height: 50px !important;
  border-radius: 10px !important;
}

.select-style-route-type
  > .ant-select-selection
  > .ant-select-selection__rendered {
  margin-top: 10px !important;
}

.scrollbar-like-mac::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.scrollbar-like-mac::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.sub-collapse-search > .ant-collapse-item > .ant-collapse-header {
  padding-left: 0 !important;
  border-bottom: 0 !important;
}

.sub-panel-search > .ant-collapse-content > .ant-collapse-content-box {
  padding-left: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 12px !important;
}
.scrollbar {
  float: left;
  height: 450px;
  width: 380px;
  overflow-y: scroll;
  margin-bottom: 10px;
}

#style-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#style-1::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

#style-1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(110, 110, 110);
}

#style-1::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.modal-edit-delete-route-smart-dispatching > .ant-modal-content {
  border-radius: 20px !important;
}

.modal-edit-delete-route-smart-dispatching
  > .ant-modal-content
  > .ant-modal-header {
  border-radius: 20px 20px 0 0 !important;
}
.concrete-dashboard-map-style {
  height: 550px !important;
  width: 100% !important;
}

.line-step-style-concrete-dashboard {
  min-height: 60px; /* ความสูงของเส้น */
  height: auto;
  width: 3px; /* ความหนาของเส้น */
  background-color: #ff0000; /* สีของเส้น */
  margin-left: 64px;
}

.start-icon-concrete-dashboard {
  height: 22px !important;
}

.end-icon-concrete-dashboard {
  height: 27px !important;
}

.clock-icon-concrete-dashboard {
  height: 22px !important;
}

.distance-concrete-dashboard {
  color: #172b4d !important;
  font-size: 24px !important;
  margin-bottom: 0 !important;
  font-weight: 600 !important;
  padding: 15px 15px 0 0 !important;
}

.time-concrete-dashboard {
  color: gray !important;
  font-size: 0.8vw !important;
  padding-right: 15px !important;
  font-weight: 600 !important;
}

.table-header-comment-concrete-dashboard {
  margin-top: 20px !important;
  font-size: 0.9vw !important;
  font-weight: 600 !important;
  color: #172b4d !important;
}

.button-add-comment-concrete-dashboard {
  background-color: #172b4d !important;
  color: white !important;
  border-color: #172b4d !important;
  height: 40px !important;
}

.chronology-sub-text {
  font-size: 0.8vw !important;
  font-weight: 400 !important;
  margin-bottom: 0px !important;
  margin-top: 5px !important;
}

.chronology-main-text {
  font-size: 0.9vw !important;
  font-weight: 600 !important;
  margin-bottom: 0px !important;
  margin-top: 6px !important;
  margin-left: 40px !important;
}

.order-event-concrete-dashboard {
  font-size: 0.9vw !important;
  font-weight: 600 !important;
  color: #172b4d !important;
}

.sub-scroll-info-inside-map {
  margin-top: 25px !important;
  /* margin-bottom: 50px !important; */
}

.time-chronology {
  margin-bottom: 10px !important;
  margin-top: 5px !important;
  font-size: 0.9vw !important;
  font-weight: 600 !important;
}

/* .dest-point-concrete-dashboard {
  margin-left: 5px !important;
  margin-top: 4px !important;
  font-size: 0.8vw !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2) !important;
  width: 98% !important;
  color: #172b4d !important;
  padding-bottom: 15px !important;
  font-weight: 600 !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
} */
.start-point-concrete-dashboard {
  font-size: 0.8vw !important;
  font-weight: 600 !important;
  color: #172b4d !important;
}

.start-point-concrete-dashboard label {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.dest-point-concrete-dashboard {
  margin-left: 5px !important;
  margin-top: 4px !important;
  font-size: 0.8vw !important;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important; */
  width: 98% !important;
  color: #172b4d !important;
  padding-bottom: 15px !important;
  font-weight: 600 !important;
}

.dest-point-concrete-dashboard label {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.table-sorter-tracking {
  text-align: center !important;
}

@media only screen and (max-width: 1290px) {
  .popup-master-data-tracking {
    position: absolute;
    margin: 0.25rem 0.938rem 0rem 0rem;
    right: -6.562rem;
    top: 2.188rem;
    z-index: 12;
    width: 26.875rem;
    height: 2.688rem;
    background-color: rgb(255, 255, 255, 0.9);
    border-radius: 0.188rem;
    border: 0.063rem solid #d9d9d9;
  }

  .concrete-dashboard-map-style {
    height: 300px !important;
    width: 100% !important;
  }

  .content-card-scroll {
    height: 200px !important;
    margin-bottom: 80px !important;
  }
  .line-step-style-concrete-dashboard {
    margin-left: 44px;
  }
  .start-icon-concrete-dashboard {
    height: 16px !important;
  }

  .end-icon-concrete-dashboard {
    height: 20px !important;
  }

  .clock-icon-concrete-dashboard {
    height: 16px !important;
  }

  .button-style-dashboard-concrete {
    width: 120px !important;
    height: 30px !important;
    background-color: #ff455f !important;
    color: white !important;
    border-radius: 10px !important;
    box-shadow: 0px 3px 11px #00000029 !important;
    margin-right: 10px;
    font-size: 10px !important;
  }

  .distance-concrete-dashboard {
    color: #172b4d !important;
    font-size: 1vw !important;
    margin-bottom: 0 !important;
    font-weight: 600 !important;
    padding: 15px 15px 0 0 !important;
  }

  .time-concrete-dashboard {
    color: gray !important;
    font-size: 1vw !important;
    padding-right: 15px !important;
    font-weight: 600 !important;
  }

  .button-add-comment-concrete-dashboard {
    background-color: #172b4d !important;
    color: white !important;
    border-color: #172b4d !important;
    height: 30px !important;
    font-size: 10px !important;
  }
  .time-chronology {
    margin-bottom: 10px !important;
    margin-top: 10px !important;
    font-size: 0.9vw !important;
    font-weight: 600 !important;
  }
  /* 
  .changeImgSrc {
    height: 30px;
    width: 30px;
    background: url('/img/square-grey-02.png');
  } */

  .changeImgSrc {
    display: block;
    position: relative;
  }
  .changeImgSrc > img {
    background: #fff;
  }
  .changeImgSrc > img:not(:only-child):nth-child(1) {
    position: absolute;
    z-index: 3;
  }
  .changeImgSrc:hover > img:nth-child(1) {
    z-index: 1;
  }
  .changeImgSrc > img:nth-child(2) {
    position: relative;
    z-index: 2;
  }
}

.smart__dipatching__map {
  height: calc(100vh - 50px) !important;
  width: 100% !important;
}

.select__hover__neighborhood__information {
  color: #172b4d;
  cursor: pointer;
}

.select__hover__neighborhood__information:hover {
  color: #f53c56;
}

.white__space__word__wrap {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.copy-generate-token-icon {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 16px;
}

.copy-generate-token-icon:hover {
  color: #f53c56;
}

.content-fuel-noti {
  width: 100%;
  height: 10%;
  padding: 30px 20px 30px 20px;
  /* UI Properties */
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-dadce0);
  background: #ffffff;
  box-shadow: 0px 3px 6px #2c28281c;
  border: 1px solid #dadce0;
  border-radius: 10px;
  opacity: 1;
}

.sub-content-fuel-noti {
  cursor: pointer;
}

.list-vehicle-card-fuel-notification {
  background-color: #fff !important;
}

.list-vehicle-card-fuel-notification:hover {
  background-color: #ffb5be !important;
}

.display-fuel-notification > .ant-popover-content > .ant-popover-arrow {
  display: none !important;
}

.display-popover-noti {
  width: 400px;
}

.uploadVehicleContractStyle > .ant-upload-list-text {
  display: none !important;
}

.reject-button {
  width: 120px;
  height: 42px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #ff0b0b;
  border: 1px solid var(--main-color, #ff0b0b);
  background: #ff0b0b;
  background: var(--main-color, #ff0b0b);
  padding: 10px;
  color: white;
  margin: auto;
}

.approve-button {
  width: 120px;
  height: 42px;
  border-radius: 8px;
  border: 1px solid #2dce98;
  border: 1px solid var(--green, #2dce98);
  background: #2dce98;
  background: var(--green, #2dce98);
  text-align: center;
  padding: 10px;
  color: white;
  margin: auto;
}

.salmon-button {
  width: 120px;
  height: 42px;
  border-radius: 8px;
  border: 1px solid #960018;
  border: 1px solid var(--green, #960018);
  background: #960018;
  background: var(--green, #960018);
  text-align: center;
  padding: 10px;
  color: white;
  margin: auto;
}

.depend-button {
  width: 120px;
  height: 42px;
  border-radius: 8px;
  border: 1px solid #ffc031;
  border: 1px solid var(--green, #ffc031);
  background: #ffc031;
  background: var(--green, #ffc031);
  text-align: center;
  padding: 10px;
  color: white;
  margin: auto;
}

.history-table {
  margin: 0px 2px 5px 0px !important;
  width: auto;
}

.history-table div table thead tr th {
  font-size: 0.75rem;
  color: white;
  background-color: #ff0b0b !important;
}

.history-table div table tbody tr td {
  font-size: 14px;
  padding: 5px !important;
}

.contractor-vehicle-management-table {
  margin: 0px 2px 5px 0px !important;
  width: auto;
}

.contractor-vehicle-management-table div table thead tr th {
  font-size: 0.85rem;
  color: white;
  padding: 13px !important;
  background-color: #ff0b0b !important;
}

.contractor-vehicle-management-table div table tbody tr td {
  font-size: 14px;
  padding: 5px 8px !important;
}

.appove-request-to-use-vehicle-table {
  margin: 0px 0px 0px 0px !important;
  width: auto;
}

.appove-request-to-use-vehicle-table div table thead tr th {
  font-size: 0.85rem;
  color: white;
  padding: 13px !important;
  background-color: #ff0b0b !important;
}

.appove-request-to-use-vehicle-table div table tbody tr td {
  font-size: 14px;
  padding: 5px 8px !important;
}

.list-vehicle-management-table {
  margin: 0px 2px 5px 0px !important;

  width: auto;
}

.list-vehicle-management-table div table thead tr th {
  font-size: 0.85rem;
  color: white;
  padding: 13px !important;
  background-color: #ff0b0b !important;
}

.list-vehicle-management-table div table tbody tr td {
  font-size: 14px;
  padding: 5px 8px !important;
}

.switch-vehicle-management-table::after {
  width: 20px !important;
  height: 20px !important;
  top: 4px !important;
  border-radius: 16.5px !important;
}

.switch-vehicle-management-table > .ant-switch-inner {
  margin-right: 0px;
}

.control-switch > .ant-switch-checked {
  background-color: #2dce98;
}

.vehicle-management-table-address {
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: left;
}

.iconConfirmListofVehicleAccount {
  border-radius: 24px;
  background: #ff8282;
  background: var(--light-blue, #ff8282);
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}

.modal-date-time-overlap > .ant-modal-content {
  border-radius: 20px !important;
}

.modal-date-time-overlap > .ant-modal-content > .ant-modal-header {
  border-radius: 20px 20px 0 0 !important;
}

.clearBoxShadowAppoveRequestToUseVehicle {
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0) !important;
}

/* :root .ant-dropdown-trigger > .anticon.anticon-down,
:root .ant-dropdown-link > .anticon.anticon-down {
  color: white !important;
  font-size: 14px !important;
} */

.select-all-tranfers-data {
  color: black;
  cursor: pointer;
}

.select-all-tranfers-data:hover {
  color: red;
  transform: scale(1.02);
}

.disable-select-all-tranfers-data {
  color: #dddddd;
  cursor: not-allowed;
}

.filter-selected-list-of-vehicle {
  overflow: scroll;
  scrollbar-width: thin;
}

.header-filter-avaliable-account {
  font-size: 13px !important;
  line-height: 2px !important;
}

.custom-line-height > .ant-form-item-control-wrapper > .ant-form-item-control {
  line-height: 20px !important;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

.replay-diable-select-table-moving {
  pointer-events: none;
}

tr[class='ant-table-row checkLastTypeOfChild ant-table-row-level-0'] > td {
  border-bottom: 3px solid black !important;
}

.ant-table-fixed-header .ant-table-scroll .ant-table-header {
  /* overflow: hidden !important; */
}

.ellipsis-two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3em;
  line-height: 1.5em;
}

.clickRowStyl {
  background-color: #172b4d;
  color: #ffffff;
  font-size: '16px';
  font-family: 'Prompt, Medium' !important;
}

.custom-table-truckincorrectpoint-Yellow {
  background-color: #ffc031;
  color: #ffffff;
  font-size: '16px';
  font-family: 'Prompt, Medium' !important;
}

.custom-table-truckincorrectpoint-Orange {
  background-color: #ff7f00;
  color: #ffffff;
  font-size: '16px';
  font-family: 'Prompt, Medium' !important;
}

.custom-table-truckincorrectpoint-Red {
  background-color: #f53c56;
  color: #ffffff;
  font-size: '16px';
  font-family: 'Prompt, Medium' !important;
}

.custom-table-truckincorrectpoint-Default {
  background-color: #ffffff;
  color: #000000;
  font-size: '16px';
  font-family: 'Prompt, Medium' !important;
}

.marker-labels {
  margin-top: -3px;
  padding: 5px;
  position: absolute;
  visibility: visible;
  z-index: 1030;
  transform: translate(-35%, -70%);
}

.marker-labels .arrow {
  border-top-color: #ffffff;
  border-right-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0);
  border-left-color: rgba(0, 0, 0, 0);
  border-width: 5px 5px 0;
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-style: solid;
  height: 0;
  position: absolute;
  width: 0;
}

.marker-labels .arrow-point {
  border-radius: 50px;
  border: 1px solid #ffffff;
  bottom: -18.85px;
  background-color: #28bdaf;
  left: 50%;
  margin-left: -6px;
  height: 10px;
  position: absolute;
  width: 10px;
  z-index: 1;
}

.marker-labels .arrow-point-2 {
  border-radius: 50px;
  border: 2px solid #28bdaf;
  bottom: -22px;
  background-color: #ffffff;
  left: 50%;
  margin-left: -9px;
  height: 14px;
  position: absolute;
  width: 14px;
}

.marker-labels .arrow-point-3 {
  border-radius: 50px;
  border: 2px solid #28bdaf;
  bottom: -22px;
  background-color: #ffffff;
  left: 50%;
  margin-left: -9px;
  height: 16px;
  position: absolute;
  width: 16px;
}

.marker-labels .inner {
  background-color: #ffffff;
  border: 1px solid #ededed;
  border-radius: 32px;
  color: #515151;
  font-weight: 700;
  width: 100px;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
  font-size: 11px;
}

.marker-labels .site-icon-marker {
  width: 18px;
  height: 18px;
  padding-top: 5%;
  padding-left: 5%;
  position: absolute;
  bottom: 25%;
  left: 5%;
}

.marker-labels .site-icon-marker-truck-incorrect-point {
  width: 22px;
  height: 22px;
  padding-top: 3%;
  padding-left: 5%;
  position: absolute;
  bottom: 25%;
  left: 5%;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    padding: 8px 8px !important;
    }
    
    .ant-table-thead > tr > th {
    background:none !important;
    }
/* progressbar */
.unload-progress {
  position: relative;
}

.alerticon {
  position: absolute;
  left: 29px;
  top: 16px;
}

.progressbar {
  position: absolute;
  left: 2px;
  top: 41px;
  width: 57px;
  height: 6px;
  border: 1px solid;
}

.percentage {
  height: 100%;
  background-color: #ff0000;
}

.alert {
  margin: 0px;
  width: 50px;
  height: 50px;
  float: left;
}
.engine-on {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAPNJREFUeNpi/P//PwMMVJzIalx0a3ohAxYQp5bZ32ExrR7GZ0HXlKlbxotN4/TLXWADYZoZQTYS0oSk+TPMZuYfHq+I0gQCpuLW7HOvTzR6/f0VB6PkQoZPxGhCt5kJxPj5/wdeXGvYicKHB873/19wmt5rPJcBmxqwxq//PsMFZpiuAtMZp8NQ2OgA7EcnDXcUPy4x2wFnx5zywNC078ZOiB9BzkDGwSdtwApANLoczMlMoHg5fvPoZ/QA8TlhgjWgQGpBepiPzDl9ABQvO25tMpIUlmLHFw3nbp2FJwBGWFqFpR4jNWNeQprgSY6cRA4QYADJb9tx3rn4AQAAAABJRU5ErkJggg==) no-repeat scroll center
    center transparent;
}
.engine-off {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAPNJREFUeNpi/P//PwMMVJzIalx0a3ohAxYQp5bZ32ExrR7GZ0HXlKlbxotN4/TLXWADYZoZQTYS0oSk+TPMZuYfHq+I0gQCpuLW7HOvTzR6/f0VB6PkQoZPxGhCt5kJxPj5/wdeXGvYicKHB873/19wmt5rPJcBmxqwxq//PsMFZpiuAtMZp8NQ2OgA7EcnDXcUPy4x2wFnx5zywNC078ZOiB9BzkDGwSdtwApANLoczMlMoHg5fvPoZ/QA8TlhgjWgQGpBepiPzDl9ABQvO25tMpIUlmLHFw3nbp2FJwBGWFqFpR4jNWNeQprgSY6cRA4QYADJb9tx3rn4AQAAAABJRU5ErkJggg==) no-repeat scroll center
    center transparent;
}
.state0-yellow {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll 0px 0px
    transparent;
}
.state1-yellow {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -50px 0px transparent;
}
.state2-yellow {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -100px 0px transparent;
}
.state3-yellow {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -150px 0px transparent;
}
.state4-yellow {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -200px 0px transparent;
}
.state5-yellow {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -250px 0px transparent;
}
.state6-yellow {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -300px 0px transparent;
}
.state7-yellow {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -350px 0px transparent;
}
.state7-red {
  background: url("/app-v1-assets/concrete/alert-icon.png") no-repeat scroll -350px
    0px transparent;
}
.state8-yellow {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -400px 0px transparent;
}
.state9-yellow {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -450px 0px transparent;
}
.state10-yellow {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -500px 0px transparent;
}
.state0-red {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll 0px -50px transparent;
}
.state1-red {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -50px -50px
    transparent;
}
.state2-red {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -100px -50px
    transparent;
}
.state3-red {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -150px -50px
    transparent;
}
.state4-red {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -200px -50px
    transparent;
}
.state5-red {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -250px -50px
    transparent;
}
.state6-red {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -300px -50px
    transparent;
}
.state7-red {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -350px -50px
    transparent;
}
.state8-red {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -400px -50px
    transparent;
}
.state9-red {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -450px -50px
    transparent;
}
.state10-red {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -500px -50px
    transparent;
}
.state0-green {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll 0px -100px transparent;
}
.state1-green {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -50px -100px
    transparent;
}
.state2-green {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -100px -100px
    transparent;
}
.state3-green {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -150px -100px
    transparent;
}
.state4-green {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -200px -100px
    transparent;
}
.state5-green {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -250px -100px
    transparent;
}
.state6-green {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -300px -100px
    transparent;
}
.state7-green {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -350px -100px
    transparent;
}
.state8-green {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -400px -100px
    transparent;
}
.state9-green {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -450px -100px
    transparent;
}
.state10-green {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -500px -100px
    transparent;
}
.state0-white {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll 0px -150px transparent;
}
.state1-white {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -50px -150px
    transparent;
}
.state2-white {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -100px -150px
    transparent;
}
.state3-white {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -150px -150px
    transparent;
}
.state4-white {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -200px -150px
    transparent;
}
.state5-white {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -250px -150px
    transparent;
}
.state6-white {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -300px -150px
    transparent;
}
.state7-white {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -350px -150px
    transparent;
}
.state8-white {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -400px -150px
    transparent;
}
.state9-white {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -450px -150px
    transparent;
}
.state10-white {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -500px -150px
    transparent;
}

.state8-drum_problem {
  background: url(/static/media/alert-icon.1aa762a7.png) no-repeat scroll -543px -150px
    transparent;
}


.editable-row .ant-form-explain {
  position: absolute;
  font-size: 12px;
  margin-top: -4px;
}
  
  /* .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  } */
  
  /* .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px 11px;
  } */
